@font-face {
  font-family: 'social-icons';
  src:  url('../fonts/social-icons.eot?i7urd8');
  src:  url('../fonts/social-icons.eot?i7urd8#iefix') format('embedded-opentype'),
    url('../fonts/social-icons.ttf?i7urd8') format('truetype'),
    url('../fonts/social-icons.woff?i7urd8') format('woff'),
    url('../fonts/social-icons.svg?i7urd8#social-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.si {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'social-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.social-dribbble:before {
  content: "\e900";
  color: inherit;
  font-size: 13px;
}
.social-facebook:before {
  content: "\e901";
  color: inherit;
  font-size: 13px;
}
.social-flickr:before {
  content: "\e902";
  color: inherit;
  font-size: 8px;
}
.social-google-plus:before {
  content: "\e903";
  color: inherit;
  font-size: 12px;
}
.social-rss:before {
  content: "\e904";
  color: inherit;
  font-size: 12px;
}
.social-skype:before {
  content: "\e905";
  color: inherit;
  font-size: 14px;
}
.social-twitter:before {
  content: "\e906";
  color: inherit;
  font-size: 11px;
}
