/**********************
DEFAULT
***********************/

body {
	font-family: 'Open Sans', sans-serif;
	font-size: 15px;
	color: #637988;
	line-height: 20px;
}

ul, ol {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, label, button, .btn, p {
	margin: 0;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	display: block;
}

h1, .h1 {
	font-size: 110px;
	font-weight: 600;
	text-transform: uppercase;
}

h2.section-title,
.h2.section-title {
	font-size: 70px;
	font-weight: 600;
	text-transform: uppercase;
	color: #2c4755;
}

h2.section-title:after,
.h2.section-title:after {
	display: block;
	content: '';
	width: 100%;
	height: 13px;
	background: url('../img/svg/separator.svg') center center no-repeat;
	margin-top: 34px;
}

.title-content {
	text-align: center;
}

.title-content p {
	margin-top: 34px;
}

h3, .h3 {
	font-size: 35px;
	font-weight: 600;
	text-transform: uppercase;
}

h4, .h4 {
	font-size: 20px;
	font-weight: 400;
	text-transform: none;
}

section {
	position: relative;
	background-color: #ffffff;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	
}
#visapage .overlay
{
	background: #ED213A;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #93291E, #ED213A);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #93291E, #ED213A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
#visapage .overlaymaid
{
	background: #56ab2f;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #a8e063, #56ab2f);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #a8e063, #56ab2f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
#visapage .overlayresidence
{
	background: #232526;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #414345, #232526);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #414345, #232526); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

#home .redshade{
	background: rgb(255,0,15);
background: linear-gradient(72deg, rgba(255,0,15,1) 0%, rgba(0,0,0,1) 68%);
}

 .greenshade{background: rgb(255,255,255);
	background: linear-gradient(212deg, rgba(255,255,255,1) 24%, rgba(9,123,6,1) 62%);}
	
#services .greenshade{ background: rgb(21,22,22);
	background: linear-gradient(202deg, rgba(21,22,22,1) 31%, rgba(3,206,1,1) 100%);} 
#home .whiteshade{background: rgb(21,22,22);
	background: linear-gradient(270deg, rgba(21,22,22,1) 41%, rgba(27,145,154,1) 86%);
}
.EHSshade{
	background: rgb(255,255,255);
background: linear-gradient(265deg, rgba(255,255,255,1) 20%, rgba(191,165,128,1) 65%, rgba(146,102,39,1) 100%);
}
.Emirate-shade{background: rgb(255,255,255);
	background: linear-gradient(265deg, rgba(255,255,255,1) 45%, rgba(212,47,54,1) 64%, rgba(24,157,110,1) 84%);
}
/**********************
BUTTONS
***********************/

.btn-default {
	padding: 9px 0;
	width: 150px;
	letter-spacing: 0.025em;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 600;
	border-radius: 20px;
	box-sizing: border-box;
	transition: all 0.3s;
	outline: none;
	box-shadow: none;	
}

.btn-default.white-yellow {
	color: #ffe600;
	background-color: #ffffff;
	border: 1px solid #ffffff;
}

.btn-default.white-yellow:hover,
.btn-default.white-yellow.active.focus, 
.btn-default.white-yellow.active:focus, 
.btn-default.white-yellow.active:hover, 
.btn-default.white-yellow:active.focus, 
.btn-default.white-yellow:active:focus, 
.btn-default.white-yellow:active:hover {
	color: #ffffff;
	background: #ffe600;
	border: 1px solid #ffffff;
	box-shadow: none;
	outline: none;
}

.btn-default.yellow-white {
	color: #ffffff;
	background-color: #ffe600;
	border: 1px solid #ffe600;
}

.btn-default.yellow-white:hover,
.btn-default.yellow-white.active.focus, 
.btn-default.yellow-white.active:focus, 
.btn-default.yellow-white.active:hover, 
.btn-default.yellow-white:active.focus, 
.btn-default.yellow-white:active:focus, 
.btn-default.yellow-white:active:hover {
	color: #ffe600;
	background: #ffffff;
	border: 1px solid #ffe600;
	box-shadow: none;
	outline: none;
}

.btn-default.white-blue {
	color: #334752;
	background-color: #ffffff;
	border: 1px solid #ffffff;
}

.btn-default.white-blue:hover,
.btn-default.white-blue.active.focus, 
.btn-default.white-blue.active:focus, 
.btn-default.white-blue.active:hover, 
.btn-default.white-blue:active.focus, 
.btn-default.white-blue:active:focus, 
.btn-default.white-blue:active:hover {
	color: #ffffff;
	background: #334752;
	border: 1px solid #ffffff;
	box-shadow: none;
	outline: none;
}

/**********************
SOCIAL ICONS
***********************/

.social-list {
	margin-top: 45px;
}

.social-list li {
	display: inline-block;
	margin-right: 17px;
}

.social-list li:last-of-type {
	margin-right: 0;
}

.social-list a {
	position: relative;
	display: block;
	width: 35px;
	height: 35px;
	color: #324651;
	background: #fafafa;
	border: 1px solid #ffffff;
	border-radius: 100%;
	box-sizing: border-box;
	text-decoration: none;
	text-align: center;
	transition: all 0.3s ease-out;
}

.social-list a:hover {
	color: #ffffff;
	background: #ffe600;
}
.whatsappBtn
{
	position: fixed;
	
}
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}
.float-call-btn{
	position:fixed;
	width:60px;
	height:60px;
	bottom:110px;
	right:40px;
	background-color:red;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
	max-height: 25px;
	color: white;
}

.si {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	line-height: 35px;
}
.linkedIn
{
	position: absolute;
	top: 26%;
	right: 24%;

	
}
/**********************
NAVIGATION
***********************/

#main-navigation.navbar {
	min-height: 100px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 1;
	margin-bottom: 0;
}

#main-navigation.navbar .navbar-nav {
    display: inline-block;
    float: none;
}

#main-navigation.navbar .navbar-collapse {
    text-align: center;
}

#main-navigation.navbar-fixed-top .navbar-collapse {
	max-height: 380px;
}

#main-navigation.navbar-default {
	background-color: #ffffff;
}

#main-navigation .navbar-brand, 
#main-navigation.navbar-default .navbar-nav>li>a.navbar-brand {
	height: 100px;
	padding: 29px 25px 23px;
}

#main-navigation .navbar-brand img {
	max-height: 48px;
}

#brand-mobile {
	display: none;
}

#main-navigation .navbar-brand-center {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

#brand-desktop {
	display: block;
}

.navbar-default .navbar-nav>li>a {
	font-size: 15px;
	color: #334752;
	padding: 40px 25px;
}
#main-navigation.navbar-default .navbar-nav>li>a:focus, 
#main-navigation.navbar-default .navbar-nav>li>a:hover {
	color: #ffe600;
	background-color: transparent;
}

#main-navigation.navbar-default .navbar-nav>.active>a, 
#main-navigation.navbar-default .navbar-nav>.active>a:focus, 
#main-navigation.navbar-default .navbar-nav>.active>a:hover {
	color: #ffe600;
	background-color: transparent;
}

#main-navigation.navbar-default .navbar-toggle {
	margin-top: 34px;
	margin-bottom: 34px;
	border: 0;
	border-radius: 0;
}

#main-navigation.navbar-default .navbar-toggle:focus, 
#main-navigation.navbar-default .navbar-toggle:hover {
	background-color: transparent;
}

#main-navigation.navbar-default .navbar-toggle .icon-bar {
	background: #ffe600;
	width: 22px;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

#main-navigation .navbar-toggle.collapsed .icon-bar {
	background: #334752;
}

.navbar-toggle .icon-bar:nth-of-type(1) {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transform-origin: 10% 10%;
	-ms-transform-origin: 10% 10%;
	transform-origin: 10% 10%;
}

.navbar-toggle .icon-bar:nth-of-type(2) {
	opacity: 0;
	filter: alpha(opacity=0);
}

.navbar-toggle .icon-bar:nth-of-type(3) {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-webkit-transform-origin: 10% 90%;
	-ms-transform-origin: 10% 90%;
	transform-origin: 10% 90%;
}

.navbar-toggle.collapsed .icon-bar:nth-of-type(1) {
	-webkit-transform: rotate(0);
	-ms-transform: rotate(0);
	transform: rotate(0);
}

.navbar-toggle.collapsed .icon-bar:nth-of-type(2) {
	opacity: 1;
	filter: alpha(opacity=100);
}

.navbar-toggle.collapsed .icon-bar:nth-of-type(3) {
	-webkit-transform: rotate(0);
	-ms-transform: rotate(0);
	transform: rotate(0);
}

/**********************
HOME SECTION
***********************/



.carousel {
	width: 100%;
	height: 100%;
}

.firstBox {
	height: 100%;
	width: 100%;
	background: url(../img/services_background.png) center center no-repeat;
	background-size: cover;
	position: relative;
}
.HomeTextBox{
	position: absolute;
	top: 15%;
	left: 5%;
	height: 70%;
	width: 90%;

}
.HomeTextInner{
	
	color: #fafafa;
	height: 30%;
	top: 60%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction:column;
	border-radius: 30px;
	justify-content: center;
	vertical-align:middle;
	
}
.down-text-motion{
	opacity: 0;
	animation: fadeDown 1.5s ease-in-out forwards;
}

@keyframes fadeDown {
	0% {
	  opacity: 0;
	  transform: translateY(-20px);
	}
	100% {
	  opacity: 1;
	  transform: translateY(0);
	}
  }
#home .img-container{
	animation: fadeDown 3s;
}


/**********************
SERVICES SECTION
***********************/

#services {
	padding: 91px 0 0;
}
#home {
	padding: 91px 0 0;
}
.services-container {
	position: relative;
	margin-top: 89px;
	padding: 0 0 56px;
	
}

.services-container .line {
	position: absolute;
	top: 56px;
	width: 80%;
	left: 10%;
	border-top: 2px solid #ffe600;
}


.services-container .service {
	max-width: 350px;
	margin: 0 auto;
	height: 350px;
	box-shadow:	0px 0px 10px  ;
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	transition: box-shadow 0.3s; 
}
.services-container .service:hover{
	cursor: pointer;
	box-shadow:	0px 0px 30px  ;
	
}

.services-container .service h4 {
	margin-top: 43px;
	text-transform: uppercase;
	font-weight: 600;
}

.services-container .service p {
	margin-top: 28px;
}

.we-make-section {

	padding: 30px 0 30px 0;
	background: url(../img/services_background.png) center center no-repeat;
	background-size: cover;
	position: relative;
	
}
.whyUs{
	height:350px;
	display: flex;
	justify-content: center;
}
.we-make-section .img-container {
	margin-bottom: -48px;
}

.we-make-section h3 {
	color: #ffe600;;
	margin-top: 15px;
}

.we-make-section p {
	margin-top: 37px;
	color: #ffffff;
}

.we-make-section .features-list {
	margin: 44px 0 0 28px;
	color: #ffffff;
	text-transform: uppercase;
}

.we-make-section .features-list li {
	position: relative;
	line-height: 30px;
	margin-left: 60px;
	margin-bottom: 10px;
}

.we-make-section .features-list li:before {
	content: '';
	display: block;
	position: absolute;
	width: 30px;
	height: 30px;
	left: -60px;
	background: url('../img/svg/circle_plus.svg') center center no-repeat;
}

.services-cta-container {
	padding: 35px 0;
	text-align: center;
	background: #ffe500;
}

/**********************
PORTFOLIO SECTION
***********************/

#portfolio-navigation {
	margin-top: 91px;
	text-align: center;
	margin-bottom: 0;
}

#portfolio-navigation .navbar-nav {
	display: inline-block;
	float: none;
}

#portfolio-navigation .navbar-nav>li>.filter {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	text-transform: uppercase;
	padding: 10px 20px;
	margin: 0 5px;
	color: #637988;
	background: transparent;
	border: 1px solid transparent;
	border-radius: 20px;
	box-sizing: border-box;
	transition: all 0.3s;
	outline: none;
	box-shadow: none;
}

#portfolio-navigation .navbar-nav>li>.filter:hover,
#portfolio-navigation .navbar-nav>li>.filter:focus {
	color: #ffe600;
}

#portfolio-navigation .navbar-nav>li>.filter.mixitup-control-active, 
#portfolio-navigation .navbar-nav>li>.filter.mixitup-control-active:hover,
#portfolio-navigation .navbar-nav>li>.filter.mixitup-control-active:focus {
	color: #ffffff;
	background: #ffe600;
	border: 1px solid #ffe600;
	box-shadow: none;
}

.portfolio-grid {
	margin-top: 25px;
	font-size: 0.1px;
	text-align: justify;
}

.portfolio-grid:after {
	content: '';
	display: inline-block;
	width: 100%;
}

.portfolio-grid .grid-item, .portfolio-grid .gap {
	position: relative;
	display: inline-block;
	margin-top: 29px;
	width: calc(100%/3 - (((3 - 1) * 29px) / 3));
}

.portfolio-grid .grid-item img {
	width: 100%;
}

#portfolio .btn {
	display: block;
	margin: 52px auto 0;
}

.videobox{
	border-radius: 3;
}
.video-section {
	padding: 20px 0;
	background: #ffe500;
	color: #ffffff;
}

.video-section h4 {
	text-transform: uppercase;
	font-weight: 600;
	color: #334752;
}

.video-section p {
	margin-top: 21px;
}

.video-section i {
	font-weight: 300;
	color: #334752;
}

.video-section .video-description {
	margin-top: 31px;
}

.video-section .video-description p:not(:first-of-type) {
	margin-top: 10px;
}

#portfolio .video-section .btn {
	display: inline-block;
	margin: 22px 0 0;
}

.business-section {
	padding: 96px 0 0;
	background: #324651;
	color: #ffffff;
	text-align: center;
	overflow: hidden;
}

.business-section p {
	margin-top: 17px;
}

.business-section i {
	font-weight: 300;
}

#portfolio .business-section .btn {
	display: inline-block;
	margin: 36px 0 0;	
}

.business-section .img-container {
	margin-top: 51px;
	margin-bottom: -47px;
}

/**********************
ABOUT US SECTION
***********************/

#about {
	padding: 86px 0 0;
}

.about-team {
	margin-top: 55px;
}

.about-team .team-member:focus {
	outline: none;
}

.about-team .team-member .img-container img {
	display: inline-block;
}

.about-team .team-member .description {
	max-width: 100%;
}

.about-team .team-member h4, 
.about-team .team-member .h4 {
	margin-top: 64px;
	font-size: 25px;
	font-weight: 600;
	color: #ffe600;
}

.about-team .team-member .position {
	margin-top: 18px;
	font-weight: 600;
	color: #334752;
	text-transform: uppercase;
}

.about-team .team-member .biography {
	margin-top: 38px;
}

.about-team .team-member .biography p:not(:first-of-type) {
	margin-top: 15px;
}

/**********************
SLIDER OPTIONS
***********************/

.team-member.slick-slide {
	opacity: 0.2;
	transition: opacity 1s ease-out;
}
 
.team-member.slick-slide.slick-center {
	opacity: 1;
	transition: opacity 1s ease-out;
}

/**********************
SKILLS & COMPANY SUBSECTION
***********************/

.skills-company-subsection {
	padding: 90px 0;
	background: #324651;
	color: #ffffff;
}

.skills-company-subsection h4 {
	color: #ffe600;
	font-weight: 600;
	text-transform: uppercase;
}

.skills-subsection {
	margin-top: 20px;
}

.skills-subsection p {
	margin-top: 20px;
	text-transform: uppercase;
	font-weight: 300;
}

.skills-subsection .progress {
	margin-top: 15px;
	height: 15px;
	border-radius: 10px;
	background-color: #ffffff;
}

.skills-subsection .progress-bar {
	line-height: 15px;
	border-radius: 10px;
	background-color: #ffe600;
}

.company-subsection .description {
	margin-top: 65px;
}

.company-subsection .description p:not(:first-of-type) {
	margin-top: 15px;
}

/**********************
BLOG SECTION
***********************/

#blog {
	padding: 93px 0 0;
	background: #fafafa;
}

.first-row-entry {
	margin-top: 93px;
}

.second-row-entry {
	margin-top: 32px;
}

.blog-entry {
	background: #ffffff;
	border-bottom: 2px solid #c2cfd7;
}

.blog-entry-inner {
	padding: 24px 21px 17px;
	overflow: hidden;
}

.blog-entry img {
	width: 100%;
}

.blog-entry a {
	text-decoration: none;
	color: #ffe600;
}

.blog-entry a:hover,
.blog-entry a:focus,
.blog-entry a:active {
	color: #637988;
}

.blog-entry .author-date {
	margin-top: 11px;
	font-size: 11px;
}

.blog-entry .entry-content {
	margin-top: 25px;
}

.blog-entry .read-more {
	display: block;
	margin-top: 20px;
	text-transform: uppercase;
	font-weight: 600;
}

#articles .btn {
	display: block;
	margin: 55px auto 0;
}

#blog .newsletter {
	margin-top: 95px;
	padding: 53px 0;
	background: #ffe600;
	color: #ffffff;
}

#blog .newsletter h3,
#blog .newsletter .h3 {
	font-size: 30px;
	text-align: center;
}

#blog .newsletter-form {
	margin-top: 38px;
	text-align: center;
}

#blog .newsletter-form #Email {
	display: block;
	width: 100%;
	padding: 9px 22px;
	border: 1px solid #ffffff;
	border-radius: 20px;
	color: #637988;
	font-weight: 300;
	outline: none;
	transition: border 0.3s ease-out;
}

#blog .newsletter-form #Email:focus,
#blog .newsletter-form #Email:active {
	outline: none;
	border: 1px solid #637988;
}

#blog .newsletter-form #Email::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-style: italic;
}
#blog .newsletter-form #Email::-moz-placeholder { /* Firefox 19+ */
  font-style: italic;
}
#blog .newsletter-form #Email:-ms-input-placeholder { /* IE 10+ */
  font-style: italic;
}
#blog .newsletter-form #Email:-moz-placeholder { /* Firefox 18- */
  font-style: italic;
}

#blog .newsletter-form .btn {
	display: block;
	width: 100%;
    max-width: 170px;
    font-size: 13px;
    padding: 10px 0;
}

/**********************
CONTACT US SECTION
***********************/

#contact {
	padding: 89px 0 93px;
	background: #324651;
	color: #ffffff;
}

#contact .section-title {
	color: #ffffff;
}

#contact .contact-form {
	margin-top: 92px;
}

#contact .contact-form input, 
#contact .contact-form textarea {
	display: block;
	width: 100%;
	font-weight: 300;
	padding: 18px 26px;
	color: #ffffff;
	background: transparent;
	border: 1px solid #ffffff;
	border-radius: 5px;
}

#contact .contact-form input:focus,
#contact .contact-form input:active,
#contact .contact-form textarea:focus,
#contact .contact-form textarea:active {
	outline: none;
}

#contact .contact-form textarea {
	resize: none;
}

#contact .contact-form input::-webkit-input-placeholder,
#contact .contact-form textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #ffffff;
}
#contact .contact-form input::-moz-placeholder,
#contact .contact-form textarea::-moz-placeholder { /* Firefox 19+ */
  color: #ffffff;
}
#contact .contact-form input:-ms-input-placeholder,
#contact .contact-form textarea:-ms-input-placeholder { /* IE 10+ */
  color: #ffffff;
}
#contact .contact-form input:-moz-placeholder,
#contact .contact-form textarea:-moz-placeholder { /* Firefox 18- */
  color: #ffffff;
}

#contact .contact-form .row:last-of-type {
	margin-top: 30px;
}

#contact .contact-form .btn {
	display: block;
	margin: 53px auto 0;
	font-size: 13px;
	width: 170px;
	padding: 10px 0;
}


/**********************
AMER PAGE
***********************/

.status-box
{
	background: #52c234;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #061700, #52c234);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #061700, #52c234); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}


.accord{
	
	
}
.accord-btn{
	width: 100%;
	height: 100%;
	
}

.info-box
{
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	width: 95%;
	
}
.info-box  h2
	{
		font-size: 50px;
		font-weight: bolder;
		text-align: start;
	}
.green-layer
{
	color: white;
	background: #52c234; 
background: -webkit-linear-gradient(to right, #061700, #52c234);
background: linear-gradient(to right, #061700, #52c234); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.emirate-green{
	background: rgb(15,177,110);
background: linear-gradient(280deg, rgba(15,177,110,1) 50%, rgba(255,255,255,1) 51%);
}
.red-layer
{
	color: white;
	background: #ED213A;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #93291E, #ED213A);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #93291E, #ED213A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	
}
/* .pass-box
{
	width: 101%;
	background: url(../img//visa/passport.jpg) center center no-repeat ;
	background-size: cover;
} */

.accord-btn button{
	width: 20%;
	font-size: large;
	background: #ED213A;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #93291E, #ED213A);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #93291E, #ED213A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	
	padding: 10px;
	display: flex;
	justify-content: space-around;
	border: none;
	border-radius: 10px;

	
}


/**********************
VISA PAGE
***********************/
.firstBoxVisa
{
	width: 100%;
	background: rgb(149,149,156);
background: linear-gradient(90deg, rgba(149,149,156,1) 0%, rgba(0,255,62,1) 14%, rgba(255,6,21,1) 100%);
} 
.visa-text-box{
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #182227;
}

.image-box
{
	display: flex;
	justify-content: center;
	align-items: center;
}

.visa-img
{
	height: 350px;
	width: 100%;
	padding: 30px;
	border-radius: 50px;
}




/**********************
FOOTER SECTION
***********************/

#footer {
	padding: 83px 0 90px;
	background: #182227;
	color: #ffffff;
	font-size: 20px;
	font-weight: 300;
	text-align: center;
}

#footer .tel {
	margin-top: 26px;
}

#footer .social-list {
	margin-top: 38px;
}

#footer .social-list li {
	margin-right: 21px;
}

#footer .copyright {
	margin-top: 29px;
	font-size: 15px;
}
#footer .mapbox
{
	background-color: #25d366;
	width: inherit;
	height: inherit;

}

#footer .detailbox{
	display: flex;
	justify-content: start;
	align-items: center;
	margin:7px 0 7px 0;
}
#footer .detailbox p{
	margin: 7px;
	text-align: start;
}
#footer .detailbox span{
	height: 32px;
	width: 32px;
	border-radius: 50%;
	background-color: #FFF;
	color: black;
	display: inherit;
	justify-content: center;
	align-items: inherit;
	
	}
#footer .logobox
{
max-width: 100%;
}
/**********************
Review Carousal
***********************/
.maincarousal{
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #25d366;
	height: 100%;
	width: 100%;
}

.reviewcard {
	max-width: 270px;
	margin: 0 auto;
	text-align: center;
	animation: slideRight  5s ease-in-out;
	border-radius: 30px;
	box-shadow:	0px 0px 10px  ;
	padding: 25px;
	min-height: 370px;
	min-width: 280px;
}
@keyframes slideRight {
	0% {
	  transform: translateX(-100%);
	}
	100% {
	  transform: translateX(0);
	}
  }
.starbox
{
	margin-top: 13px;
	color: #ffe600;
	display: flex;
	justify-content: space-evenly;
}

.reviewcard h4 {
	margin-top: 33px;
	text-transform: uppercase;
	font-weight: 550;
}

.reviewcard h5 {
	 font-size: larger;
	margin-top: 33px;
	text-transform: uppercase;
	font-weight: 550;
}

.reviewcard p {
	margin-top: 28px;
}


/**********************
HELPER CLASSES
***********************/

.text-yellow {
	color: #ffe600;
}

.img-container {
	text-align: center;
}

.img-container img {
	max-width: 100%;
}

.grabbable {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.grabbing { 
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

/**********************
RESPONSIVE
***********************/

@media only screen and (min-width: 1440px) {
	
	.visa-types button{
		margin-left: 10px;
	}
	.visa-text h2{
		text-align: center;
		width: inherit;
		font-weight: bolder;
	}
	.visa-text h4{
		margin-top: 1.5rem;
		text-align: start;
		width: inherit;
		font-weight: bolder;
	}
	.visa-text p{
		padding-left: 5rem;
		padding-right: 5rem;
		font-size: medium;
		font-weight: bold;
		text-align: start;
		width: inherit;
		
	}
	.visa-text{
		border-radius: 0px 15px 15px 15px;
	}
	.sub-visa{
		
		width: 30%;
	}
	.sub-box-one{
		width: 40%;
		padding: 30px;
		
	
	}
	.newID-sub-box-one{
		width: 55%;
		padding: 30px;
		
	}
	.newID-sub-box-two{
		width: 45%;
		padding: 30px;
		
	}
	.extra-box{
		background-color:white;
		padding:19%;
		border-radius:50%
	}
	.emi-sub-one{
		background-color: #ffcccc;
		border-radius: 50%;
		margin: 20px;
	}
	/**********************
	HOME SECTION
	***********************/
	.carousel-caption {
		left: 25%;
		right: 25%;
	}
	/**********************
	HOME SECTION
	***********************/
	
	.amer-box
	{
		padding: 4%;
		
	}
	.amer-box .first-box
	{
		border-radius: 0px 10px 10px 0px;
		width: 70%;
	}
}



@media only screen  and (min-width: 1280px){
	.visa-type-click{
		border-radius: 15px 15px 0 0px;
	}
	.visa-types button{
		margin-left: 10px;
		
		height: 100%;
	}
}



@media only screen and (max-width: 1199px) {
	/**************
	Amer
	***************/
	.visa-type-click{
		border-radius: 15px 15px 0 0px;
	}
	.visa-types button{
		margin-left: 10px;
	}
	
	
	/**********************
	NAVIGATION
	***********************/
	#main-navigation .container {
		width: 100%;
	}
	#main-navigation.navbar-default .navbar-nav>li>a {
		padding: 40px 23px;
	}
	#main-navigation .navbar-brand, #main-navigation.navbar-default .navbar-nav>li>a.navbar-brand {
		padding: 29px 23px 23px;
	}

	
}

@media only screen and (max-width: 991px) {

	/**********************
	EHS & Amer
	***********************/
	.visa-type-click{
		border-radius: 15px 15px 0 0px;
	}
	.visa-types button{
		margin-left: 10px;
		padding: 0.5rem;
	}

	.visa-text h2{
		margin-top: 1.5rem;
		font-weight: bolder;
		
	}
	.visa-text{
		border-radius: 0 20px 20px 20px;
		

	}
	.visa-text h4{
		margin-top: 1.5rem;
		font-weight: bold;
	
	}
	.visa-text p{
		width: inherit;
		display: flex;
		align-items: start;
		justify-content: start;	
	}
	.amer-box
	{
	flex-direction: column;

	}
	.EHSshade{
		background: rgb(255,255,255);
		background: linear-gradient(180deg, rgba(255,255,255,1) 40%, rgba(153,112,54,1) 76%);}
	.Emirate-shade{
		background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(255,255,255,1) 45%, rgba(212,47,54,1) 64%, rgba(24,157,110,1) 84%);
	}

	.sub-box-one img{

		width: 100%;
	}
	/**********************
	SERVICES SECTION
	***********************/
	.services-container .service {
		margin-top: 45px;
	}
	.reviewcard
	{
		margin-top: 45px;
	}
	.services-container .line {
		display: none;
	}

	.features-container {
		max-width: 650px;
		margin: 0 auto;
		padding: 0 15px;
		text-align: center;
	}

	.we-make-section .img-container {
		margin-bottom: 0;
		margin: 60px auto;
		
	}
	
	

	.we-make-section .features-list {
		display: inline-block;
		margin: 44px auto 0;
		text-align: left;
	}

	/**********************
	PORTFOLIO SECTION
	***********************/
	.video-section h4 {
		margin-top: 45px;
	}

	#portfolio .video-section .btn {
		display: block;
		margin: 30px auto 0;
	}

	/**********************
	ABOUT US SECTION
	***********************/
	.about-team .team-member .description {
		text-align: center;
		margin-bottom: 45px;
	}

	.about-team .team-member h4, 
	.about-team .team-member .h4 {
		margin-top: 35px;
	}

	.about-team .team-member .description .biography {
		text-align: left;
	}

	/**********************
	SKILLS & COMPANY SUBSECTION
	***********************/
	.company-subsection h4 {
		margin-top: 90px;
	}

	.company-subsection .description {
		margin-top: 30px;
	}
	
	/**********************
	CONTACT US SECTION
	***********************/
	#contact .contact-form .row div:not(:first-of-type) {
		margin-top: 30px;
	}

	/**********************
	HELPER CLASSES
	***********************/

	/* Reverse the order of columns on xs*/
    .row.reorder-sm {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
        direction: rtl; /* Fix the horizontal alignment */
    }

    .row.reorder-sm > [class*="col-"] {
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        transform: rotate(-180deg);
        direction: ltr; /* Fix the horizontal alignment */
    }

}

@media only screen and (max-width: 767px) {
	.visa-types{
		flex-direction: column;
		width: 100%;
		margin: 0px;
	}
	.visa-type-click{
		border-radius: 15px 15px 15px 15px;
	}
	.visa-types button{
		margin: 0px;
	}
	.visa-text{
		margin-bottom: 50px;
		margin-top:20px ;
		border-radius: 20px 20px 20px 20px;
		
	}
	.sub-visa{
		justify-content: space-between;
		width: 100%;
	}
	/**********************
	DEFAULT
	***********************/
	.videobox{
		width: 105%	;
		padding: 0px;
		margin: 0px;
	}
	.info-box{
		flex-direction: column;
	}
	
	.info-box  h2
	{
		font-size: 50px;
		font-weight: 100;
	}
	h2.section-title {
		font-size: 50px;
	}
	/**********************
	DED
	******************/
	.DED-container h3{
		font-size: 25px;
		text-align: center;
		
	}
	.DED-sub-container{
		flex-direction: column;
		
	}
	.DED-card 
	{
		width:90%;
		padding: 0;
		margin: 0;
	}
	
	.DED-card h4{
		font-size: 18px;
		
		
	}

	/*********************
	Amer
	************/
	
	.first-box h3{
		font-size: x-large;
	}
	.first-box p{
		font-size: medium;
	}
	.second-box{
		margin-top: 20px;
		margin-bottom: 20px;
		width: 100%;
		display: flex;
		justify-content: center;
	}
	.second-box img{
		width: 90%;
		height: 240px;
		
	}
/**********************
	EHS
	***********************/
	
	 .sub-box-one{
		width: 100%;
	}

	/**********************
	SERVICES
	***********************/
	.services-container .row{
		flex-direction: column;
	}

	/**********************
	NAVIGATION
	***********************/

	#main-navigation.navbar .navbar-nav {
	    display: block;
	}

	#brand-mobile {
		display: block;
	}

	#main-navigation .navbar-brand-center {
		padding: 29px 45px 23px;
	}

	#brand-desktop {
		display: none;
	}

	#main-navigation.navbar-default .navbar-nav>li>a {
		padding: 20px 0;
	}

	/**********************
	HOME SECTION
	***********************/
	

	/**********************
	PORTFOLIO SECTION
	***********************/

	.portfolio-grid .grid-item, .portfolio-grid .gap {
		width: calc(100% - (30px * 2));
		margin-left: 30px;
		margin-right: 30px;
	}

	.business-section .img-container {
		margin: 50px 0 30px;
	}

	/**********************
	ABOUT US SECTION
	***********************/
	.team-member.slick-slide {
		opacity: 1;
	}
	
	/**********************
	BLOG SECTION
	***********************/
	.first-row-entry, .second-row-entry {
		margin-top: 45px;
	}

	#blog .newsletter-form #Email {
		max-width: 370px;
		margin: 0 auto;
	}

	#blog .newsletter-form .btn {
		max-width: 170px;
		margin: 30px auto 0;
	}
}

/**********************
Amer
******************/
.visa-text{
	background-color: #d6f7e7;
	padding: 2rem;
	flex-direction: column;
	min-height: 30rem;

}


.fade-text-golden{
	animation: fadeGolden  3s;
	

}

.fade-text-work{
	animation: fadeWork  3s;

}

.fade-text-family{
	animation: fadeFamily 3s;

}

.fade-text-cancel{
	animation: fadeCancel  3s;

}

@keyframes fadeGolden {
	0% { opacity: 0; }
	100% { opacity: 1; }
  }
  @keyframes fadeFamily {
	0% { opacity: 0; }
	100% { opacity: 1; }
  }
  @keyframes fadeWork {
	0% { opacity: 0; }
	100% { opacity: 1; }
  }
  @keyframes fadeCancel {
	0% { opacity: 0; }
	100% { opacity: 1; }
  }


@keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
  }




.amer-box
{
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	
	
}





.first-box{
	min-height: 200px;
	min-width: 300px;
	padding: 25px;
	
}


.first-box p
{
	margin-top: 10px;

}


.first-box .head
{
	font-size: larger;
	font-weight: bold;

}

/**********************
DED
******************/

.DED-container
{
	padding-top: 50px;
	display: flex;
	align-items: center;
	flex-direction: column;
	
	width: 100%;
	
}

.DED-sub-container{
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 50px;
	flex-wrap: wrap;
	
}
.DED-card{
	min-height: 150px;
	display:inline;
	padding: 10px;
	margin: 10px;
	text-align: center;
	
}
.DED-card img{
	max-height: 60px;
}
.DED-card h4{
	margin-top: 20px;
	font-weight: bold;
}

 .sub-box-one{
	display:  flex;
	justify-content: center;
	align-items: center;
	
	border-radius: 50%;
	/* background: url('../img/visa/EHS/dotted.png') center center no-repeat ;
	background-size: cover; */
}
 .sub-box-two{
	background-color: whitesmoke;
	min-height: 230px;
	border-radius: 5px;
	margin: 30px;
	padding: 30px;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: first baseline;
	
}

.sub-box-two h4{
	padding-top: 10px;
	padding-bottom: 10px;
}
.sub-box-two p{
	margin-top: 10px;
}

.sub-box-one img{
	max-width: 400px;
	
}

.visa-types{
	display: flex;
	justify-content: start;
	align-items: center;
	width: 100%;
	padding-top: 50px;
	animation: fadeIn 1s;
}



.visa-types button {
	border: none;
	
}
.hover-style:hover{
	background-color: #D6F7E7;
	-webkit-transform: translateY(-1px);
	transform: translateY(-5px);
	
	transition: all 0.7s;
	border-radius: 15px 15px 0 0px;
	
	

}
.visa-type-click{
	background-color: #D6F7E7;
	

}
.checking{
	background-color: red;
	transition: all 2s;
}
.visa-non-click{
	background-color:transparent;
}
.sub-visa{
	display:  flex;
	justify-content: center;
	align-items: center;
	background-color: white;

}
.sub-visa-one
{
	width: 50%;
	padding: 2%;
	

	
}

.sub-visa-two
{
	width: 50%;
	padding: 2%;
}
.sub-visa .icon{
	text-align: center;
	
}
.sub-visa h4{
	font-size: small;
	margin-top: 10px;
	text-align: center;
	font-weight: bolder;
}
.sub-visa img{
	max-height: 70px;
	
	
}
.talent-box{
	display: flex;
	justify-content: start;
	align-items: start;
	flex-wrap: wrap;
}

.talent-box p{
	width: 50%;
	
}